import React, { Suspense, lazy } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import DashboardLayout from '../layouts/dashboard';
import LoadingScreen from '../components/LoadingScreen';
import { useAccount } from 'wagmi';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen isDashboard={true} />}>
    <Component {...props} />
  </Suspense>
);

// Custom higher-order component for checking access token and isConnected
const ProtectedRoute = ({ element, ...rest }) => {
  const accessTokenExists = localStorage.getItem('access_token') !== null;
  const isConnected = useAccount().isConnected;
  const walletTypeDetails = localStorage.getItem('access_token');
  const parsed = JSON.parse(walletTypeDetails);
  const walletType = parsed?.wallet_type

  if (!accessTokenExists || (!isConnected && walletType === 'external')) {
    return <Navigate to="/" />;
  }

  return React.cloneElement(element, rest);
};

export default function Router() {
  return useRoutes([
    {
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Home /> },
        { path: '/minter', element: <ProtectedRoute element={<Minter />} /> },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      element: <ProtectedRoute element={<DashboardLayout />} />,
      children: [
        { path: 'rewards', element: <ProtectedRoute element={<Rewards />} /> },
        { path: 'nfts', element: <ProtectedRoute element={<NFTs />} /> },
        { path: 'nfts/1155/mint', element: <ProtectedRoute element={<QuickMintLayout />} /> },
        { path: 'claimables', element: <ProtectedRoute element={<Claimables />} /> },
        { path: 'claimables/new', element: <ProtectedRoute element={<ClaimableLayout />} /> },
        { path: 'utility', element: <ProtectedRoute element={<Utility />} /> },
        { path: 'utility/gate', element: <ProtectedRoute element={<ContentGating />} /> },
        { path: 'utility/gate/new', element: <ProtectedRoute element={<ContentGatingLayout />} /> },
        { path: 'settings', element: <ProtectedRoute element={<Settings />} /> },
      ],
    },
  ]);
}

// Lazy-loaded components
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
const Home = Loadable(lazy(() => import('../pages/Home')));
const Minter = Loadable(lazy(() => import('../pages/Minter')));
const Utility = Loadable(lazy(() => import('../pages/Utility')));
const ContentGating = Loadable(lazy(() => import('../pages/utility/apps/ContentGating')));
const ContentGatingLayout = Loadable(lazy(() => import('../pages/gate/ContentGatingLayout')));
const NFTs = Loadable(lazy(() => import('../pages/NFTs')));
const QuickMintLayout = Loadable(lazy(() => import('../pages/mint/quickmint/QuickMintLayout')));
const Claimables = Loadable(lazy(() => import('../pages/Claimables')));
const ClaimableLayout = Loadable(lazy(() => import('../pages/claimable/ClaimableLayout')));
const Settings = Loadable(lazy(() => import('../pages/Settings')));
const Rewards = Loadable(lazy(() => import('../pages/Rewards')));
