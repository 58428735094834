import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


//localstorage get access token
const local_access_token = localStorage.getItem('access_token');
const access_token = JSON.parse(local_access_token);
//----------------------------------------------------------------------


//-----------------------------------------------------------------------------------------------------

//Get current user's data

export const getUserData = createAsyncThunk("contract/getUserData", async () => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/users/me`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            'Authorization': `Bearer ${access_token.token}`,
        },
    }).then((res) => 
    res.json()
    );
})

//Get unique name by wallet
export const getUserName = createAsyncThunk("contract/getUserName", async ({ username }) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/users/exists/${username}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        'Authorization': `Bearer ${access_token.token}`,
      },
    }).then((res) => res.json());
})

//Update user's data

export const updateUserData = createAsyncThunk("contract/updateUserData", async ({updatedUserData, id }) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/users/me`, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            'Authorization': `Bearer ${access_token.token}`,
            "x-auth-id": id,
        },
        body: JSON.stringify({
            avatar: updatedUserData.avatar,
            username: updatedUserData.username,
            socials: updatedUserData.socials
        })
    }).then((res) => 
    res.json()
    );
})

//Update user Rewards 

export const updateUserRewardStreak = createAsyncThunk("contract/updateUserRewardStreak", async () => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/users/claim-gems`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            'Authorization': `Bearer ${access_token.token}`,
        },
    }).then((res) => 
    res.json()
    );
})


//-----------------------------------------------------------------------------------------------------

//Get quick mint list by wallet
export const getQuickMint = createAsyncThunk("contract/getQuickMint", async () => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/quick`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            'Authorization': `Bearer ${access_token.token}`,
        },
    }).then((res) => 
    res.json()
    );
})

//-----------------------------------------------------------------------------------------------------

//--------------------------------------------Quick Mint POST---------------------------------------------------------

//Post request for quick minting
export const quickMint = createAsyncThunk("contract/quickMint", async ({mintData}) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/quick/mint`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            'Authorization': `Bearer ${access_token.token}`,
        },
        body: JSON.stringify({
            name: mintData.name,
            description: mintData.description,
            contentImageUrl: mintData.contentImageUrl,
            external_url: mintData.external_url,
            royalty: mintData.royalty,
            tokenID: mintData.tokenID,
            contractAddress: mintData.contractAddress,
            amount: mintData.amount,
            metadata: mintData.metadata,
            metadataType: mintData.metadataType,
            chains: mintData.chains,
            utility_tags: mintData.utility_tags,
        })
    }).then((res) => 
    res.json()
    );
})


//Update utility tags
export const updateQuickMint = createAsyncThunk("contract/updateQuickMint", async ({utilityData, id}) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/quick/update/${id}/`, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            'Authorization': `Bearer ${access_token.token}`,
        },
        body: JSON.stringify({
            utility_tags: utilityData.utility_tags,
        })
    }).then((res) => 
    res.json()
    );
});

//------------------------------------------------Claimable--------------------------------------------

//Get all claimable claim list by wallet
export const getAllClaimable = createAsyncThunk("contract/getAllClaimable", async () => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/claimable`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            'Authorization': `Bearer ${access_token.token}`,
        },
    }).then((res) => 
    res.json()
    );
})

//Get all claimable mint with claim false (Available Claimable claim mint lists)
export const getAllClaimableMint = createAsyncThunk("contract/getAllClaimableMint", async () => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/claimable/mints`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            'Authorization': `Bearer ${access_token.token}`,
        },
    }).then((res) => 
    res.json()
    );
})

//delete claimable
export const deleteClaimable = createAsyncThunk("contract/deleteClaimable", async ({id}) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/claimable/delete/${id}`, {
        method: "DELETE",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            'Authorization': `Bearer ${access_token.token}`,
        },
    }).then((res) => 
    res.json()
    );
})

//Post request for Claimable
export const createNewClaimable = createAsyncThunk("contract/createNewClaimable", async ({claimableData}) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/claimable/new`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            'Authorization': `Bearer ${access_token.token}`,
        },
        body: JSON.stringify({
            contractAddress: claimableData.contractAddress,
            mint: claimableData.mint,
            price: claimableData.price,
            claimers: claimableData.claimers,
            total_claimed: claimableData.total_claimed,
            claimVerify_signature: claimableData.claimVerify_signature,
            active: claimableData.active,
            unlockable: claimableData.unlockable,
            unlockableContent: claimableData.unlockableContent,
            creator: claimableData.creator
        })
    }).then((res) => 
    res.json()
    );
})


//Update Claimables claim status
export const updateClaimable = createAsyncThunk("contract/updateClaimable", async ({claimableData, id }) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/claimable/update/${id}/`, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            'Authorization': `Bearer ${access_token.token}`,
        },
        body: JSON.stringify({
            active: claimableData.active
        })
    }).then((res) => 
    res.json()
    );
});


//Get claimablemint by Id 
export const getClaimableMintId = createAsyncThunk("contract/getClaimableMintId", async ({contractAddress, tokenID}) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/claimable/${contractAddress}/${tokenID}`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        },
    }).then((res) => 
    res.json()
    );
})


//------------------------------------------------Relayer-------------------------------------------------------------

//Relay claimable mint transaction
export const relayGaslessMint = createAsyncThunk("contract/relayGaslessMint", async ({relayData}) => {
    return fetch(`${process.env.REACT_APP_RELAYER_URL}/api/relay/mint`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            "x-auth-relayer": relayData.relayer,
            "x-auth-creator": relayData.creator,
            "x-auth-supply": relayData.supply,
            "x-auth-royalty": relayData.royalty,
            "x-auth-metadataURI": relayData.metadataURI,
        },
    }).then((res) => 
    res.json()
    );
})

//------------------------------------------------------------------------------------------------------------------

//------------------------------------------------Token Gating--------------------------------------------

//Get all gated list by wallet
export const getAllGated = createAsyncThunk("contract/getAllGated", async () => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/gate/content`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            'Authorization': `Bearer ${access_token.token}`
        },
    }).then((res) => 
    res.json()
    );
})


//Post request for Gated Content
export const createNewGated = createAsyncThunk("contract/createNewGated", async ({gatedData}) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/gate/content/nft`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            'Authorization': `Bearer ${access_token.token}`,
        },
        body: JSON.stringify({
            name: gatedData.name,
            description: gatedData.description,
            contentImageUrl: gatedData.contentImageUrl,
            chain: gatedData.chain,
            tokenID: gatedData.tokenID,
            contractAddress: gatedData.contractAddress,
            fileType: gatedData.fileType,
            unlockableContent: gatedData.unlockableContent,
            creator: gatedData.creator
        })
    }).then((res) => 
    res.json()
    );
})

//delete gated
export const deleteGated = createAsyncThunk("contract/deleteGated", async ({id}) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/gate/content/delete/${id}`, {
        method: "DELETE",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            'Authorization': `Bearer ${access_token.token}`
        },
    }).then((res) => 
    res.json()
    );
})

//Update gated content
export const updateGated = createAsyncThunk("contract/updateGated", async ({gatedData, id}) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/gate/content/update/${id}/`, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            'Authorization': `Bearer ${access_token.token}`
        },
        body: JSON.stringify({
            active: gatedData.active,
            name: gatedData.name,
            description: gatedData.description,
        })
    }).then((res) => 
    res.json()
    );
});

//---------------------------------General API calls --------------------------

//Get all general utility list
export const getAllGeneralUtility = createAsyncThunk("contract/getAllGeneralUtility", async () => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/general/utility`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        },
    }).then((res) => 
    res.json()
    );
});

//Get all general extension list
export const getAllGeneralExtension = createAsyncThunk("contract/getAllGeneralExtension", async () => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/general/extension`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        },
    }).then((res) => 
    res.json()
    );
});

//Get all general chains list
export const getAllGeneralChains = createAsyncThunk("contract/getAllGeneralChains", async () => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/general/chains`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        },
    }).then((res) => 
    res.json()
    );
});

//Get all general quick contracts list
export const getAllGeneralContracts = createAsyncThunk("contract/getAllGeneralContracts", async () => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/general/contracts/quick`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        },
    }).then((res) => 
    res.json()
    );
});

//Get all general relayers list
export const getAllGeneralRelayers = createAsyncThunk("contract/getAllGeneralRelayers", async () => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/general/relayers`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        },
    }).then((res) => 
    res.json()
    );
});

//Get all general price list
export const getAllGeneralPrice = createAsyncThunk("contract/getAllGeneralPrice", async () => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/general/price`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        },
    }).then((res) => 
    res.json()
    );
});

//Get all general reward (gems) list
export const getAllGeneralReward = createAsyncThunk("contract/getAllGeneralReward", async () => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/general/rewards`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        },
    }).then((res) => 
    res.json()
    );
});

//-------------------------------------------------------------------------------------------------------------------

const contractSlice = createSlice({
    name: "contract",
    initialState: {
        //------------ user states --------
        
        userData: [], //user details
        userName: null,
        loadingUserDetails: false,
        loadingUserRewards: false,
        
        //---------------Mint States-------------
        quickMint: [],
        loadingQuickMintUpdate: false,

        claimable: [], //claimable details
        claimableMint: [], //claimable mints
        //------------------------------
        details: null, //mint details
        claimableDetails: null, //claimable mint details
        verifyStatus: null, //verification data
        loading: false,
        loadingVerify: false, //task verify loading
        
        //--------------------Claimable states -------------
        loadingClaimable: false,
        loadingClaimableUpdate: false,
        loadingClaimableMint: false,
        loadingClaimableDetails: false,
        error: null,
        //-----------------Relayer states -----------------
        relayerData: [],
        loadingRelayer: false,
        //------------------Gated states--------------------------
        loadingGated: false,
        loadingGatedUpdate: false,
        gated: [], //gated list

        //---------------------General APIs states-----------------------
        loadingGeneral: false,
        generalUtility: [],
        generalExtension: [],
        generalChains: [],
        generalContracts: [],
        generalContractsLazy: [],
        generalRelayers: [],    
        generalPrice: [],
        generalReward: [],
        //--------------------------------------------

    },


    extraReducers: {

        //get users data
        [getUserData.pending]: (state, action) => {
            state.loading = true;
        },
        [getUserData.fulfilled]: (state, action) => {
            state.loading = false;
            state.userData = [action.payload];
        },
        [getUserData.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },

        //get user name by wallet
        [getUserName.pending]: (state, action) => {
            state.loading = true;
        },
        [getUserName.fulfilled]: (state, action) => {
            state.loading = false;
            state.userName = action.payload;
        },
        [getUserName.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },

        //Update users data
        [updateUserData.pending]: (state, action) => {
            state.loadingUserDetails = true;
        },
        [updateUserData.fulfilled]: (state, action) => {
            state.loadingUserDetails = false;
            state.userData = [action.payload];
        },
        [updateUserData.rejected]: (state, action) => {
            state.loadingUserDetails = false;
            state.error = action.payload;
        },

        //Update users reward streak
        [updateUserRewardStreak.pending]: (state, action) => {
            state.loadingUserRewards = true;
        },
        [updateUserRewardStreak.fulfilled]: (state, action) => {
            state.loadingUserRewards = false;
            state.userData = [action.payload];
        },
        [updateUserRewardStreak.rejected]: (state, action) => {
            state.loadingUserRewards = false;
            state.error = action.payload;
        },


        //get all quickMint list by wallet
        [getQuickMint.pending]: (state, action) => {
            state.loading = true;
        },
        [getQuickMint.fulfilled]: (state, action) => {
            state.loading = false;
            state.quickMint = [action.payload];
        },
        [getQuickMint.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },


        //post quick mint
        [quickMint.pending]: (state, action) => {
            state.loading = true;
        },
        [quickMint.fulfilled]: (state, action) => {
            state.loading = false;
            state.quickMint = action.payload;
        },
        [quickMint.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },

        //update quickMint
        [updateQuickMint.pending]: (state, action) => {
            state.loadingQuickMintUpdate = true;
        },
        [updateQuickMint.fulfilled]: (state, action) => {
            state.loadingQuickMintUpdate = false;
            state.quickMint = [action.payload];
        },
        [updateQuickMint.rejected]: (state, action) => {
            state.loadingQuickMintUpdate = false;
            state.error = action.payload;
        },

        //get all claimable claim list by wallet
        [getAllClaimable.pending]: (state, action) => {
            state.loadingClaimable = true;
        },
        [getAllClaimable.fulfilled]: (state, action) => {
            state.loadingClaimable = false;
            state.claimable = [action.payload];
        },
        [getAllClaimable.rejected]: (state, action) => {
            state.loadingClaimable = false;
            state.error = action.payload;
        },

        //get all claimable mint list by wallet
        [getAllClaimableMint.pending]: (state, action) => {
            state.loadingClaimableMint = true;
        },
        [getAllClaimableMint.fulfilled]: (state, action) => {
            state.loadingClaimableMint = false;
            state.claimableMint = [action.payload];
        },
        [getAllClaimableMint.rejected]: (state, action) => {
            state.loadingClaimableMint = false;
            state.error = action.payload;
        },


        //post claimable
        [createNewClaimable.pending]: (state, action) => {
            state.loadingClaimable = true;
        },
        [createNewClaimable.fulfilled]: (state, action) => {
            state.loadingClaimable = false;
            state.claimable = action.payload;
        },
        [createNewClaimable.rejected]: (state, action) => {
            state.loadingClaimable = false;
            state.error = action.payload;
        },

        //update claimable
        [updateClaimable.pending]: (state, action) => {
            state.loadingClaimableUpdate = true;
        },
        [updateClaimable.fulfilled]: (state, action) => {
            state.loadingClaimableUpdate = false;
            state.claimable = [action.payload];
        },
        [updateClaimable.rejected]: (state, action) => {
            state.loadingClaimableUpdate = false;
            state.error = action.payload;
        },


        //delete claimable
        [deleteClaimable.pending]: (state, action) => {
            state.loadingClaimableUpdate = true;
        },
        [deleteClaimable.fulfilled]: (state, action) => {
            state.loadingClaimableUpdate = false;
            state.claimable = [action.payload];
        },
        [deleteClaimable.rejected]: (state, action) => {
            state.loadingClaimableUpdate = false;
            state.error = action.payload;
        },

        //get claimable mint by Id
        [getClaimableMintId.pending]: (state, action) => {
            state.loadingClaimableDetails = true;
        },
        [getClaimableMintId.fulfilled]: (state, action) => {
            state.loadingClaimableDetails = false;
            state.claimableDetails = [action.payload];
        },
        [getClaimableMintId.rejected]: (state, action) => {
            state.loadingClaimableDetails = false;
            state.error = action.payload;
        },
        

        //claimable mint relayer response
        [relayGaslessMint.pending]: (state, action) => {
            state.loadingRelayer = true;
        },
        [relayGaslessMint.fulfilled]: (state, action) => {
            state.loadingRelayer = false;
            state.relayerData = [action.payload];
        },
        [relayGaslessMint.rejected]: (state, action) => {
            state.loadingRelayer = false;
            state.error = action.payload;
        },
       
        //get all gated list by wallet
        [getAllGated.pending]: (state, action) => {
            state.loadingGated = true;
        },
        [getAllGated.fulfilled]: (state, action) => {
            state.loadingGated = false;
            state.gated = [action.payload];
        },
        [getAllGated.rejected]: (state, action) => {
            state.loadingGated = false;
            state.error = action.payload;
        },

         //post gated
        [createNewGated.pending]: (state, action) => {
            state.loadingGated = true;
        },
        [createNewGated.fulfilled]: (state, action) => {
            state.loadingGated = false;
            state.gated = action.payload;
        },
        [createNewGated.rejected]: (state, action) => {
            state.loadingGated = false;
            state.error = action.payload;
        },

        //delete gated
        [deleteGated.pending]: (state, action) => {
            state.loadingGated = true;
        },
        [deleteGated.fulfilled]: (state, action) => {
            state.loadingGated = false;
            state.gated = [action.payload];
        },
        [deleteGated.rejected]: (state, action) => {
            state.loadingGated = false;
            state.error = action.payload;
        },

        //update gated
        [updateGated.pending]: (state, action) => {
            state.loadingGatedUpdate = true;
        },
        [updateGated.fulfilled]: (state, action) => {
            state.loadingGatedUpdate = false;
            state.gated = [action.payload];
        },
        [updateGated.rejected]: (state, action) => {
            state.loadingGatedUpdate = false;
            state.error = action.payload;
        },
        
        //get all general utility list 
        [getAllGeneralUtility.pending]: (state, action) => {
            state.loadingGeneral = true;
        },
        [getAllGeneralUtility.fulfilled]: (state, action) => {
            state.loadingGeneral = false;
            state.generalUtility = [action.payload];
        },
        [getAllGeneralUtility.rejected]: (state, action) => {
            state.loadingGeneral = false;
            state.error = action.payload;
        },

        //get all general extension list 
        [getAllGeneralExtension.pending]: (state, action) => {
            state.loadingGeneral = true;
        },
        [getAllGeneralExtension.fulfilled]: (state, action) => {
            state.loadingGeneral = false;
            state.generalExtension = [action.payload];
        },
        [getAllGeneralExtension.rejected]: (state, action) => {
            state.loadingGeneral = false;
            state.error = action.payload;
        },

         //get all general chains list 
        [getAllGeneralChains.pending]: (state, action) => {
            state.loadingGeneral = true;
        },
        [getAllGeneralChains.fulfilled]: (state, action) => {
            state.loadingGeneral = false;
            state.generalChains = [action.payload];
        },
        [getAllGeneralChains.rejected]: (state, action) => {
            state.loadingGeneral = false;
            state.error = action.payload;
        },

        //get all general quick contracts list 
        [getAllGeneralContracts.pending]: (state, action) => {
            state.loadingGeneral = true;
        },
        [getAllGeneralContracts.fulfilled]: (state, action) => {
            state.loadingGeneral = false;
            state.generalContracts = [action.payload];
        },
        [getAllGeneralContracts.rejected]: (state, action) => {
            state.loadingGeneral = false;
            state.error = action.payload;
        },

        //get all general relayers list 
        [getAllGeneralRelayers.pending]: (state, action) => {
            state.loadingGeneral = true;
        },
        [getAllGeneralRelayers.fulfilled]: (state, action) => {
            state.loadingGeneral = false;
            state.generalRelayers = [action.payload];
        },
        [getAllGeneralRelayers.rejected]: (state, action) => {
            state.loadingGeneral = false;
            state.error = action.payload;
        },

        //get all general price list 
        [getAllGeneralPrice.pending]: (state, action) => {
            state.loadingGeneral = true;
        },
        [getAllGeneralPrice.fulfilled]: (state, action) => {
            state.loadingGeneral = false;
            state.generalPrice = [action.payload];
        },
        [getAllGeneralPrice.rejected]: (state, action) => {
            state.loadingGeneral = false;
            state.error = action.payload;
        },

        //get all general reward list 
        [getAllGeneralReward.pending]: (state, action) => {
            state.loadingGeneral = true;
        },
        [getAllGeneralReward.fulfilled]: (state, action) => {
            state.loadingGeneral = false;
            state.generalReward = [action.payload];
        },
        [getAllGeneralReward.rejected]: (state, action) => {
            state.loadingGeneral = false;
            state.error = action.payload;
        },

    }
})


export default contractSlice.reducer;