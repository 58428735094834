// components
import Iconify from '../../../components/Iconify';
// ----------------------------------------------------------------------

const sidebarConfig = [

  {
    subheader: 'Overview',
    items: [
      { title: 'NFTs', path: '/nfts', icon: <Iconify icon="ri:nft-fill"/> }, 
      { title: 'Claimables', path: '/claimables', icon: <Iconify icon="ant-design:tag-outlined"/> },
     
    ]
  },

  {
    subheader: 'Utility Center',
    items: [
      { title: 'Utility Apps', path: '/utility', icon: <Iconify icon="fluent:apps-16-regular"/> }, 
      { title: 'Bridge', path: '/bridge', icon: <Iconify icon="mdi:bridge"/> },
    ]
  },

  
];

export default sidebarConfig;
